import React, { useState, useEffect, useCallback, createRef, forwardRef } from "react"
import { Link } from "gatsby"

import Layout from "components/layout"
import SEO from "components/seo"
// import useInterval from 'hooks/useInterval'
// import posed from 'react-pose'
import styled from 'styled-components'

import { ReactComponent as TochkakomLogoSVG } from 'images/tochkakom.svg'
import { ReactComponent as RalevLogoSVG } from 'images/ralev.svg'

const TochkakomLogo = styled(TochkakomLogoSVG)`
  width: 150px;

  @media (min-width: 768px) {
    width: 250px;
  }
  `
  
  const RalevLogo = styled(RalevLogoSVG)`
  width: 150px;

  @media (min-width: 768px) {
    width: 250px;
  }
  `

const IndexPage = () => {
  const [mounted, setMounted] = useState(false)
  const loadingBarEnd = useCallback((e) => {
    window.location = `https://ralev.com`
  })

  useEffect(() => {
    setMounted(true)

  }, [])
  // useInterval( updateLoadingBar, 50 )

  return (
    <Layout>
      <SEO />
  
      <div className="loading-bar" style={{ width: mounted ? `100%` : 0 }} onTransitionEnd={loadingBarEnd} />
  
      {/* <div className="flex"> */}
      <TochkakomLogo />

      <div className="my-6 md:my-10">е проект на</div>

      <a href="https://ralev.com"><RalevLogo /></a>
      {/* </div> */}
    </Layout>
  )
}

export default IndexPage
